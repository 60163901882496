import React from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { connect } from "react-redux";
import { tryGigyaRetrieveTokenFromCode } from "../../actions/auth";
import { tryPatientMedical } from "../../actions/medicals";

import { DefaultLoader, PractitionerLayoutAnonymous, PractitionerLayoutAsideAnonymous } from "@rdcs/dap-front-library";

import * as queryString from "query-string";

const LoginSuccessScreen = ({ auth, tryGigyaRetrieveTokenFromCode, tryPatientMedical }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const urlParams = React.useMemo(() => queryString.parse(location.search), [location.search]);

    React.useEffect(() => {
        new Promise((resolve, reject) => {
            if (auth.user !== null || !urlParams.code) {
                reject();
            }
            resolve(urlParams.code);
        })
            .then(tryGigyaRetrieveTokenFromCode)
            .then(tryPatientMedical)
            .then((medicals) => {
                if (medicals.filter(({ enable }) => enable === false).length === 0) {
                    throw new Error("No medical");
                }
                navigate("/mes-soignants");
            })
            .catch(() => navigate("/"));
    }, []);

    return (
        <PractitionerLayoutAnonymous>
            <div className="grid credentials__container">
                <PractitionerLayoutAsideAnonymous />
                <main className="col-sm-50 credentialsMain__container">
                    <div className="credentialsMain">
                        <div className="credentials__title">
                            Connectez-vous à <br />
                            Diabète Adhérence Plateforme
                        </div>
                        <div>
                            {auth.retrieveTokenFromCodeError ? (
                                <div>
                                    <div className="bgError radius shadow mt-4 mb-2 pt-2 pl-3 pb-2 pr-3">
                                        {auth.retrieveTokenFromCodeError}
                                    </div>
                                    <Link to="/" className="button button__primaryButton mx-0 mt-2">
                                        Retourner à l'accueil
                                    </Link>
                                </div>
                            ) : (
                                <DefaultLoader />
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </PractitionerLayoutAnonymous>
    );
};

LoginSuccessScreen.propTypes = {
    auth: PropTypes.object.isRequired,
    tryGigyaRetrieveTokenFromCode: PropTypes.func.isRequired,
    tryPatientMedical: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = {
    tryGigyaRetrieveTokenFromCode,
    tryPatientMedical,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginSuccessScreen);
